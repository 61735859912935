<script>
	let extraClass = '';
	export { extraClass as class };
	export let delay = 0;
	let visible = false;
	setTimeout(() => (visible = true), delay);
</script>

{#if visible}
	<div class=" {extraClass}  ">
		<object
			class="w-full h-full"
			title="shooting star"
			type="image/svg+xml"
			data="/shooting_star.svg"
		/>
	</div>
{/if}
